<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_patient") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitPatient(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content">
      <div class="add_patient">
        <el-form ref="form" :model="form" :rules="rules" class="twoform">
          <el-row :gutter="24">
            <el-col :sm="12">
              <el-form-item :label="columns.surname.title" prop="surname">
                <el-input
                  v-model="form.surname"
                  :placeholder="columns.surname.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.first_name.title" prop="first_name">
                <el-input
                  v-model="form.first_name"
                  :placeholder="columns.first_name.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.patronymic.title" prop="patronymic">
                <el-input
                  v-model="form.patronymic"
                  :placeholder="columns.patronymic.title"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-form-item :label="columns.phone.title" prop="phone">
                <el-input
                  v-model="form.phone"
                  :placeholder="columns.phone.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.born_date.title" prop="born_date">
                <el-date-picker
                  type="date"
                  v-model="form.born_date"
                  :placeholder="columns.born_date.title"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.code.title" prop="code">
                <el-input
                  :disabled="formCode"
                  v-model="form.code"
                  :value="patient_last_id"
                  :label="columns.code.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-row :gutter="10">
                <el-col :span="20">
                  <el-form-item
                    :label="columns.social_id.title"
                    prop="social_id"
                    class="mb-22"
                  >
                    <el-select
                      v-model="form.social_id"
                      :placeholder="columns.social_id.title"
                    >
                      <el-option
                        v-for="item in socials"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button @click="SocialName()" class="add__icon_kod"
                    ><i :class="buttonIcons"></i
                  ></el-button>
                </el-col>
                <el-col :span="24" v-if="socialName">
                  <el-form-item>
                    <el-input v-model="form.social_name"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="24">
              <div class="pol_radio">
                <el-form-item :label="columns.gender.title" prop="gender">
                  <el-radio-group v-model="form.gender">
                    <el-radio label="man" border size="medium">{{
                      $t("message.male")
                    }}</el-radio>
                    <el-radio label="woman" border size="medium">{{
                      $t("message.female")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content mb20">
                <el-tabs
                  v-model="activetab"
                  class="ifoData mb20"
                  type="border-card"
                >
                  <el-tab-pane :label="$t('message.organization')" name="thero">
                    <el-row :gutter="20">
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="$t('message.organization')"
                            prop="document_type_id"
                          >
                            <el-select
                              v-model="form.organization_id"
                              :placeholder="$t('message.organization')"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in list_organization"
                                :key="item.id + item.company_name"
                                :label="item.company_name"
                                :value="item.id"
                              >
                                <span style="float: left">{{
                                  item.company_name
                                }}</span>
                                <span
                                  style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                  "
                                  >{{ $t("message.inn") }} {{ item.inn }}</span
                                >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                    <!-- end row -->
                  </el-tab-pane>

                  <el-tab-pane :label="$t('message.documents')" name="first">
                    <el-row :gutter="20">
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.document_type_id.title"
                            prop="document_type_id"
                          >
                            <el-select
                              v-model="form.document_type_id"
                              :placeholder="columns.document_type_id.title"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in document_types"
                                :key="item.id + item.name"
                                :label="item.name"
                                :value="item.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.series_and_number.title"
                            prop="series_and_number"
                          >
                            <el-input
                              v-model="form.series_and_number"
                              :placeholder="columns.series_and_number.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.date_of_issue.title"
                            prop="date_of_issue"
                          >
                            <el-date-picker
                              type="date"
                              v-model="form.date_of_issue"
                              :placeholder="columns.date_of_issue.title"
                              style="width: 100%"
                              :format="'dd.MM.yyyy'"
                              :value-format="'dd.MM.yyyy'"
                            ></el-date-picker>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.authority.title"
                            prop="authority"
                          >
                            <el-input
                              v-model="form.authority"
                              :placeholder="columns.authority.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                    </el-row>
                    <!-- end row -->
                  </el-tab-pane>

                  <el-tab-pane
                    :label="$t('message.address_register')"
                    name="second"
                  >
                    <el-row :gutter="20">
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.state_id.title"
                            prop="state_id"
                          >
                            <el-select
                              v-model="form.state_id"
                              :placeholder="columns.state_id.title"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in states"
                                :key="item.id + item.name"
                                :label="item.name"
                                :value="item.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.city_id.title"
                            prop="city_id"
                          >
                            <el-select
                              v-model="form.city_id"
                              :placeholder="columns.city_id.title"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in citiesFunc"
                                :key="item.id + item.name"
                                :label="item.name"
                                :value="item.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.region.title"
                            prop="region"
                          >
                            <el-input
                              v-model="form.region"
                              :placeholder="columns.region.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.street.title"
                            prop="street"
                          >
                            <el-input
                              v-model="form.street"
                              :placeholder="columns.street.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item :label="columns.area.title" prop="area">
                            <el-input
                              v-model="form.area"
                              :placeholder="columns.area.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :span="6">
                        <div class="grid-content">
                          <el-form-item :label="columns.home.title" prop="home">
                            <el-input
                              v-model="form.home"
                              :placeholder="columns.home.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :span="6">
                        <div class="grid-content">
                          <el-form-item :label="columns.flat.title" prop="flat">
                            <el-input
                              v-model="form.flat"
                              :placeholder="columns.flat.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <!-- end col -->
                    </el-row>
                    <!-- end row -->
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-col>

            <el-col :span="24" class="mt-3">
              <el-checkbox-group v-model="form.tags" size="mini">
                <el-checkbox-button
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="tag.id"
                  >{{ tag.name }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  props: ["reopened", "drawer", "organizationId"],
  data() {
    return {
      formCode: false,
      socialName: false,
      activetab: "first",
      buttonIcons: "el-icon-plus",
      form: {},
    };
  },
  watch: {
    reopened: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.form = JSON.parse(JSON.stringify(this.model));
          this.formCode = this.patient_last_id ? true : false;
          this.form.code = this.patient_last_id + 1;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.form.organization_id = this.organizationId;
    if (this.socials && this.socials.length === 0) await this.loadSocials();
    if (this.tags && this.tags.length === 0) await this.loadTags();
    if (this.cities && this.cities.length === 0) await this.loadCities();
    if (this.states && this.states.length === 0) await this.loadStates();
    if (this.document_types && this.document_types.length === 0)
      await this.loadDocumentTypes();
    if (this.list_organization && this.list_organization.length === 0)
      await this.loadOrganization();
  },
  computed: {
    ...mapGetters({
      socials: "socials/list",
      tags: "tags/list",
      model: "patients/model",
      patient_last_id: "patients/last_model_id",
      document_types: "documentTypes/list",
      rules: "patients/rules",
      columns: "patients/columns",
      states: "states/list",
      cities: "cities/list",
      list_organization: "organizations/list",
    }),
    citiesFunc: function () {
      return this.states.filter((item) => {
        return item.id == this.form.state_id;
      });
    },
  },
  methods: {
    ...mapActions({
      loadSocials: "socials/index",
      loadOrganization: "organizations/index",
      loadTags: "tags/index",
      save: "patients/store",
      editModel: "patients/show",
      empty: "patients/empty",
      loadDocumentTypes: "documentTypes/index",
      loadStates: "states/index",
      loadCities: "cities/index",
    }),
    submitPatient(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$refs["form"].resetFields();
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.SocialName();
              if (this.form.social_name != "") {
                this.loadSocials();
              }
              if (_.isFunction(this.parent().listChanged)) {
                this.parent().listChanged();
              }
              this.$emit("c-close", {
                reload: true,
                drawer: "drawerCreate",
              });
            })
            .catch((err) => {
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              this.loadingButton = false;
            });
        }
      });
    },
    SocialName() {
      this.socialName = !this.socialName;
      this.buttonIcons = this.socialName
        ? (this.buttonIcons = "el-icon-minus")
        : (this.buttonIcons = "el-icon-plus");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: this.drawer });
    },
  },
};
</script>